<script>
// import Layout from "../../../../layouts/main";
// import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
 import { required,email , maxLength,minLength,minValue,numeric,requiredIf } from "vuelidate/lib/validators";
// import { fetchSettings } from "../api";
// import infoStaff from './infoStaff.vue';
// import Infrastructure from './infrastructure.vue';
// import Multiselect from 'vue-multiselect'
import { getDropDownElementsByTypeApi} from "@/api/common";
// import SideButtons from '../../../../../../components/side-buttons.vue';
import { emailFormatter} from "@/helpers/functions";

  import Swal from "sweetalert2";

export default {
    
    page: {
        title: "Employées Prestataire ",
        meta: [{ name: "description", content: appConfig.description}],
    },
    // import : { fetchSettings },
    components: {/* SideButtons */ },
    props:['selectedEmp','employe'],
    data() {
      return {
      tableData: [],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      title: "Nouveau Employe",
     Emp_info: {
        Emp_first_name:'',
        Emp_last_name:'',
        Emp_sexe:'',
        Emp_phone:'',
        Emp_email:'',
        Emp_business_address:'',
        Emp_n_cin:'',

        Emp_n_passport:'',
        Emp_marital:'',
        Emp_employee_number:'',
        Emp_analytic_account:'',
        Emp_supplier_id:'',
        Emp_is_externe:'',
        Emp_is_user : '',
        Emp_post : '',
        nationality :''
      },
      suppliers:[],
        etats:[
          {text:'Marié',value:"Marié"},
          {text:'divorcé',value:"divorcé"},
          {text:'célibataire',value:"célibataire"},
          {text:'veuf',value:"veuf"}
        ],
        sexes:[
            {text:'Homme',value:1},
            {text:'Femme',value:0}
        ],
      
      posts:[],
      nationalitys:[],
      disabledAdd : false,
      disabledEdit : true,
      disabledDelete : true,
      disabled : true,
      disabledCancel : true,
      lastSelected : null,
      add : false,
      edit : false,
      destroy : false,
      send : false,
      have : false,
      resetForm:false,
    };
  },
    validations: {
      Emp_info:{
        Emp_first_name      : { required },
        Emp_last_name       : { required },
        Emp_phone           : { required, numeric, maxLength: maxLength(10),minLength: minLength(9)},
        Emp_email           : { 
          required, 
          email: (val) => email(emailFormatter(val)),
        },
        Emp_sexe            : { required },
        Emp_business_address: { required },
       Emp_n_cin:{ 
                required: requiredIf(function() {
                    return this.Emp_info.Emp_n_passport ==''
                }),        
        },

        Emp_n_passport:{ 
                required: requiredIf(function() {
                    return this.Emp_info.Emp_n_cin ==''
                }),        
        },
        Emp_marital         : { required },
        Emp_employee_number : { required },
        Emp_analytic_account: { required },
        Emp_supplier_id     : { required },

        Emp_is_externe: { required },
        Emp_is_user   : { required },
        Emp_post      : { required },
        nationality   : { required : false }
        
      }
    },
  watch:{
    add(){
       this.addBtn()
      this.resetForm = false;
    },
    edit(){
      this.editBtn()
    },
    destroy(){
      this.deleteEmp()
    },
    selectedEmp(){
        this.have = false;
      if(this.selectedEmp.length != 0){
        this.addSelectedRow()     
      }else{
         this.disabled       = true;
        this.disabledAdd    = false;
        this.disabledDelete = true;
        this.disabledEdit   = true;
        this.disabledCancel = true;
        this.resetForm = true;
        this.clearForm();
      }
    }
  },
  mounted(){
    this.getSup();
    this.getNationalitys()
    this.getPost()
},

 methods:{
    addSelectedRow(){
      this.lastSelected   = this.selectedEmp;
      this.disabled       = true;
      this.disabledAdd    = false;
      this.disabledDelete = false;
      this.disabledEdit   = false;
      this.disabledCancel = true;
      this.Emp_info.uuid                 = this.selectedEmp.uuid;
      this.Emp_info.Emp_first_name       = this.selectedEmp.first_name;
      this.Emp_info.Emp_last_name        = this.selectedEmp.last_name;
      this.Emp_info.Emp_sexe             = this.selectedEmp.sexe;
      this.Emp_info.Emp_phone            = this.selectedEmp.phone;

      //this.Emp_info.Emp_mobile         = this.selectedEmp.mobile;
      this.Emp_info.Emp_email            = this.selectedEmp.email;
      this.Emp_info.Emp_business_address = this.selectedEmp.business_address;
      this.Emp_info.Emp_n_cin            = this.selectedEmp.n_cin;
      this.Emp_info.Emp_n_passport       = this.selectedEmp.n_passport;
      this.Emp_info.Emp_marital          = this.selectedEmp.marital;
      this.Emp_info.Emp_employee_number  = this.selectedEmp.employee_number;
      this.Emp_info.Emp_analytic_account = this.selectedEmp.analytic_account;

      this.Emp_info.Emp_supplier_id      = this.selectedEmp.supplier_id;   
      this.Emp_info.Emp_is_externe   = this.selectedEmp.is_externe;  
      this.Emp_info.Emp_is_user      = this.selectedEmp.is_user; 
      this.Emp_info.nationality      = this.selectedEmp.nationality;                
      this.Emp_info.Emp_post         = this.selectedEmp.post;  
   },
  addBtn(){
      this.have           = true;
      this.add            = false;
      this.disabled       = false;
      this.disabledAdd    = true;
      this.disabledEdit   = true;
      this.disabledCancel = true;
      this.clearForm();
    },
    editBtn(){
      this.have         = true;
      this.add          = false;
      this.edit         = false;
      this.disabledAdd  = true;
      this.disabledEdit = true;
      this.disabled     = false;
      this.disabledCancel = true;
    },
    cancelBtn(){
      this.disabled       = true;
      this.disabledCancel = true;
      if(this.lastSelected != null){
        this.disabledEdit   = false;
        this.addSelectedRow(this.lastSelected);
      }
      this.disabledAdd    = false;
    },
    getNationalitys(){
         getDropDownElementsByTypeApi('country')

          .then((res) => {
              this.nationalitys = res.data.list;
            })
            .catch(() => {
              // error.response.status Check status code
            })
            .finally(() => {
              //Perform action in always
            });
     },
     
    getPost(){
         getDropDownElementsByTypeApi('employee_function')

          .then((res) => {
              this.posts = res.data.original.list;
            })
            .catch(() => {
              // error.response.status Check status code
            })
            .finally(() => {
              //Perform action in always
            });
     },
    getSup(){   
      this.$http.post("/contracts/suppliers/list")
      .then((res) => {
          this.suppliers = res.data.suppliers;
      }).catch((error) => {
      this.$toast.error(error.message);
      })
      .finally(() => {});
    },
    clearForm(){
        this.Emp_info.Emp_first_name       = "";
        this.Emp_info.Emp_last_name        = "";
        this.Emp_info.Emp_sexe             = "";
        this.Emp_info.Emp_phone            = "";
        
        this.Emp_info.Emp_email            = "";
        this.Emp_info.Emp_business_address = "";
        this.Emp_info.Emp_n_cin            = "";
        this.Emp_info.Emp_n_passport       = "";
        this.Emp_info.Emp_marital          = "";
        this.Emp_info.Emp_employee_number  = "";
        this.Emp_info.Emp_analytic_account = "";
        this.Emp_info.Emp_supplier_id      = ""; 
        this.Emp_info.Emp_is_externe       = ""; 
        this.Emp_info.Emp_post             = "";
        this.Emp_info.Emp_is_externe       = ""; 
        this.Emp_info.Emp_is_user          = "";   
        this.Emp_info.nationality          = "";        
        this.Emp_info.uuid                 = "";        
    },
    formSubmit() { 
      this.submitted = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let loader = this.$loading.show({
          color: '#000000',
          loader: 'spinner',
          width: 64,
          height: 64,
          backgroundColor: '#ffffff',
          opacity: 0.5,
          zIndex: 999,
        })
        this.submitted = false
        this.$http
        .post("/referentiel/employees_prestataire/store", this.Emp_info)
        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              this.$toast.success(res.data.original.msg);
              // Swal.fire("Félicitations!", res.data.original.msg, "success");
              this.$emit('refrechTable', true);
              this.clearForm();
              this.disabled       = true;
              this.disabledCancel = true;
              this.disabledAdd    = false;
              break;
            case 500:
              Swal.fire("Avertissement!", res.data.original.msg, "warning");
              break;
          }
          loader.hide();
        })
        .catch((error) => {
          loader.hide();
          Swal.fire("Erreur!", error.message, "error");
        })
        .finally(() => {});
      }
    },
    deleteEmp() {
      this.destroy = false;
      var _this    = this;
      var typeUid  = this.Emp_info.uuid
      var typeName = this.Emp_info.Emp_first_name +" "+this.Emp_info.Emp_last_name
      Swal.fire({
        title: "Êtes-vous sûr de supprimer l'Employé  : " + typeName + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#B8CCD7",
        cancelButtonText: "Annuler",
        confirmButtonText: "Oui!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/referentiel/employees_prestataire/delete/" + typeUid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  this.$toast.success(res.data.original.msg);
                  this.$emit('refrechTable', true);
                  this.lastSelected   = null;
                  this.clearForm();
                  break;
                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
  }
    
}
</script>
<style>
  #StickyEF {
    position: fixed;
    right: 0;
    top: 50%;
    width: 8em;
    margin-top: -2.5em;
    z-index: 999;
    list-style: none;
  }
  .rounded-pillEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    border-radius: 50% !important;
  }
  .btn-addEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #044A72 ;
  }
  .btn-duplicateEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #8FC412 ;
  }
  .btn-editEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #FFB236 ;
  }
  .btn-deleteEF #dropdown-dropleft__BV_toggle_{
     border-radius: 50% !important;
  }
  .btn-deleteEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #D61337;
  }
  .btn-uploadEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #2A5769 ;
  }
  .btn-downloadEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #21BF92;
  }
  .btn-cancelEF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #B8CCD7 ;
  }
.form-check-input{
  font-size: 24px;
}
</style>
<template>
    <!-- <Layout>
    <PageHeader :title="title" :items="items" /> -->
    <div class="row">
      <div class="col-12">
        <div class="card-body">
          
           <ul id="StickyEF" >
            <li>
              <button type="button" @click="addBtn" :disabled="disabledAdd && !$can('create_employee')" class="btn btn-addEF rounded-pillEF float-right mr-1 mt-1">
              <i class="fas fa-plus text-white"></i>
              </button>
            </li>
            <li>
              <button type="button" @click="editBtn" :disabled="disabledEdit && !$can('edit_employee')" class="btn btn-editEF rounded-pillEF float-right mr-1 mt-1">
                <i class="fas fa-pencil-alt text-white" style=""></i>
              </button>
            </li>
            <li>
              <button type="button" :disabled="disabledEdit && !$can('delete_employee')" @click="deleteEmp" class="btn btn-deleteEF rounded-pillEF float-right mr-1 mt-1">
                <i class="fas fa-trash-alt text-white" style=""></i>
              </button>
            </li>
          </ul>
          <h4 class="card-title">Informations Générales</h4>
          
          <fieldset :disabled="disabled">
          <form class="needs-validation" @submit.prevent="formSubmit" enctype="multipart/form-data">
              <div class="row">
                
                <div class="col-xs-12 col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="formrow-inputCity">Prestataire *</label>
    
                    <select class="form-control" v-model="Emp_info.Emp_supplier_id" 
                    @blur="$v.Emp_info.Emp_supplier_id.$error"
                    :class="{
                      'is-invalid': submitted && $v.Emp_info. Emp_supplier_id.$error,
                    }">
                        <option v-for="supplier in suppliers" :key="supplier.id" :value="supplier.id"> {{supplier.prestname}} </option>
                    </select>
                      <div
                        v-if="submitted && $v.Emp_info. Emp_supplier_id.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.Emp_info. Emp_supplier_id.required"
                          >Le champ est obligatoire.</span
                        >
                      </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="formrow-inputCity">Matricule *</label>
    
                    <input type="text"  class="form-control" v-model="Emp_info.Emp_employee_number" 
                      @blur="$v.Emp_info.Emp_employee_number.$error"
                    :class="{
                      'is-invalid': submitted && $v.Emp_info.Emp_employee_number.$error,
                    }" placeholder=""
                    />
                      <div
                        v-if="submitted && $v.Emp_info.Emp_employee_number.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.Emp_info.Emp_employee_number.required"
                          >Le champ est obligatoire.</span
                        >
                      </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="formrow-inputCity">Nom *</label>
    
                    <input type="text"  class="form-control" v-model="Emp_info.Emp_first_name" 
                      @blur="$v.Emp_info.Emp_first_name.$touch"
                    :class="{
                      'is-invalid': submitted && $v.Emp_info.Emp_first_name.$error,
                    }" placeholder=""
                    />
                      <div
                        v-if="submitted && $v.Emp_info.Emp_first_name.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.Emp_info.Emp_first_name.required"
                          >Le champ est obligatoire.</span
                        >
                      </div>
                  </div>
                </div>
                  <div class="col-xs-12 col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="formrow-inputCity">Prénom *</label>
                    <input type="text"  class="form-control" v-model="Emp_info.Emp_last_name" 
                      @blur="$v.Emp_info.Emp_last_name.$error"
                      :class="{
                        'is-invalid': submitted && $v.Emp_info.Emp_last_name.$error,
                      }" placeholder=""
                    />
                      <div
                        v-if="submitted && $v.Emp_info.Emp_last_name.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.Emp_info.Emp_last_name.required"
                          >Le champ est obligatoire.</span
                        >
                      </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-4 col-lg-3">
                  <div class="form-group ">
                    <label for="formrow-inputCity">Sexe * </label>
                      <select class="form-control" v-model="Emp_info.Emp_sexe" 
                    @blur="$v.Emp_info.Emp_sexe.$error"
                    :class="{ 
                      'is-invalid': submitted && $v.Emp_info. Emp_sexe.$error,
                    }">
                        <option v-for="sexe in sexes" :key="sexe.text" :value="sexe.text"> {{sexe.text}} </option>
                    </select>
                      <div
                        v-if="submitted && $v.Emp_info.Emp_sexe.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.Emp_info.Emp_sexe.required"
                          >Le champ est obligatoire.</span>
                      </div>

                  </div>
                </div>
                <div class="col-xs-12 col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="formrow-inputCity">Etat Civile *</label>
                    <select class="form-control" v-model="Emp_info.Emp_marital"
                      @blur="$v.Emp_info.Emp_marital.$error" 
                    :class="{
                      'is-invalid': submitted && $v.Emp_info.Emp_marital.$error,

                    }">
                        <option v-for="etat in etats" :key="etat.text" :value="etat.text"> {{etat.text}} </option>
                    </select>
                      <div
                        v-if="submitted && $v.Emp_info.Emp_marital.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.Emp_info.Emp_marital.required"
                          >Le champ est obligatoire.</span
                        >
                      </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="formrow-inputCity">Email *</label>
    
                    <input type="text"  class="form-control" v-model="Emp_info.Emp_email" 
                      @blur="$v.Emp_info.Emp_email.$error"
                    :class="{
                      'is-invalid': submitted && $v.Emp_info.Emp_email.$error,
                    }" placeholder=""

                    />
                      <div
                        v-if="submitted && $v.Emp_info.Emp_email.$error"
                        class="invalid-feedback">
                        <span v-if="!$v.Emp_info.Emp_email.required">
                          Le champ est obligatoire . </span> 
                      <span v-if="!$v.Emp_info.Emp_email.email"> entrer un email valide.</span>
                      </div>
                  </div>
                </div>
                  <div class="col-xs-12 col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="formrow-inputCity">Télèphone *</label>
                    <input type="text"  class="form-control" v-model="Emp_info.Emp_phone" 
                      @blur="$v.Emp_info.Emp_phone.$error"
                      :class="{'is-invalid': submitted && $v.Emp_info.Emp_phone.$error,}" placeholder=""
                    />
                      <div v-if="submitted && $v.Emp_info.Emp_phone.$error" class="invalid-feedback">
                        <span v-if="!$v.Emp_info.Emp_phone.required">Le champ est obligatoire . </span>
                        <span v-if="!$v.Emp_info.Emp_phone.numeric">le Numréo de Télèphone doit étre un nombre . </span>
                        <span v-if="!$v.Emp_info.Emp_phone.minLength">La taille minimum du champs est: 9 nombres . </span>
                        <span v-if="!$v.Emp_info.Emp_phone.maxLength">La taille maximum du champs est: 10 nombre. </span>
                      </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="formrow-inputCity">Adresse *</label>
    
                    <input type="text"  class="form-control" v-model="Emp_info.Emp_business_address" 
                      @blur="$v.Emp_info.Emp_business_address.$error"
                      :class="{'is-invalid': submitted && $v.Emp_info.Emp_business_address.$error,}" placeholder=""/>
                      <div v-if="submitted && $v.Emp_info.Emp_business_address.$error" class="invalid-feedback">
                        <span v-if="!$v.Emp_info.Emp_business_address.required">Le champ est obligatoire.</span>
                      </div>
                  </div>
                </div>
            
                <div class="col-xs-12 col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="formrow-inputCity">Numéro de pièce d'identité *</label>
                    <input type="text"  class="form-control" v-model="Emp_info.Emp_n_cin" 
                      @blur="$v.Emp_info.Emp_n_cin.$error" :class="{'is-invalid': submitted && $v.Emp_info.Emp_n_cin.$error,}" placeholder=""/>
                      <div v-if="submitted && $v.Emp_info.Emp_n_cin.$error" class="invalid-feedback">
                        <span v-if="!$v.Emp_info.Emp_n_cin.required">Le champ est obligatoire.</span>
                      </div>
                  </div>
                </div>
                  <div class="col-xs-12 col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="formrow-inputCity">Numéro de passeport *</label>
                    <input type="text"  class="form-control" v-model="Emp_info.Emp_n_passport" 
                      @blur="$v.Emp_info.Emp_n_passport.$error"
                     :class="{'is-invalid': submitted && $v.Emp_info.Emp_n_passport.$error,}"/>
                      <div v-if="submitted && $v.Emp_info.Emp_n_passport.$error"  class="invalid-feedback">
                        <span v-if="!$v.Emp_info.Emp_n_passport.required">Le champ est obligatoire.</span>
                      </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="formrow-inputCity">Compte analytique *</label>
                    <input type="text"  class="form-control" v-model="Emp_info.Emp_analytic_account" 
                      @blur="$v.Emp_info.Emp_analytic_account.$error"
                    :class="{ 'is-invalid': submitted && $v.Emp_info.Emp_analytic_account.$error,}"/>
                      <div v-if="submitted && $v.Emp_info.Emp_analytic_account.$error"
                        class="invalid-feedback">
                        <span v-if="!$v.Emp_info.Emp_analytic_account.required">Le champ est obligatoire.</span>
                      </div>
                  </div>
                </div>
                <div class="col-xs-6 col-md-6 col-lg champ">
                  <div class="form-group">
                    <label for="formrow-email-input">Poste *</label>
                    <select v-model="Emp_info.Emp_post" class="form-control"
                      :class="{'is-invalid': submitted && $v.Emp_info.Emp_post.$error,
                               'isDisabled disabledBG':disabled}">
                      <option v-for="post in posts" :key="post.uuid" :value="post.uuid"> {{post.designation}} </option>
                    </select>
                    <div v-if="submitted && $v.Emp_info.Emp_post.$error" class="invalid-feedback">
                      <span v-if="!$v.Emp_info.Emp_post.required">Le champ est obligatoire.</span>
                    </div>
                  </div>
                </div>
                <div class="col-xs-6 col-md-6 col-lg champ">
                  <div class="form-group">
                    <label for="formrow-email-input">Nationalité *</label>
                    <select v-model="Emp_info.nationality" class="form-control"
                      :class="{
                        'is-invalid': submitted && $v.Emp_info.nationality.$error,
                        'isDisabled disabledBG':disabled}">
                      <option v-for="nat in nationalitys" :key="nat.uuid" :value="nat.uuid"> {{nat.designation}} </option>
                    </select>
                    <div v-if="submitted && $v.Emp_info.nationality.$error"
                      class="invalid-feedback">
                      <span v-if="!$v.Emp_info.nationality.required">
                        Le champ est obligatoire.</span>
                    </div>
                  </div>
                </div>
                <div class="col-xs-6 col-md-4 col-lg-3 mt-4">
                  <b-form-checkbox
                      v-model="Emp_info.Emp_is_externe"
                      switch  :value="1"
                      class="mb-3"
                      >Employe Est Résidant</b-form-checkbox
                    >
                </div>
                <div class="col-xs-6 col-md-4 col-lg-3 mt-4">
                  <b-form-checkbox v-model="Emp_info.Emp_is_user" switch  :value="1" class="mb-3">
                    Est  un Utilisateur
                  </b-form-checkbox>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12" v-show="!disabled && ($can('create_employee') || $can('edit_employee'))">
                <button type="button"  @click="cancelBtn" class="btn btn-light btn-label float-right ml-2">
                  <i class="fas fa-times-circle text-danger label-icon"></i> Annuler
                </button>
                <button type="submit" class="btn btn-label btn-primary float-right" >
                  <i class="far fa-save label-icon "></i> Enregistrer
                </button>
                </div>
              </div>
                      
          </form>
          </fieldset>
        </div>
        <!-- end card-body -->
      
      </div>
    </div>
    <!-- </Layout> -->
</template>
